/**
 * @generated SignedSource<<2398381cd1568c4499801b19d6322037>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompletedDialogEdgeFragment$data = {
  readonly initiative: {
    readonly completionSuccessContent: string;
    readonly id: string;
  };
  readonly seenCompleted: boolean;
  readonly seenIntro: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"goalProgressHookEdgeFragment">;
  readonly " $fragmentType": "CompletedDialogEdgeFragment";
};
export type CompletedDialogEdgeFragment$key = {
  readonly " $data"?: CompletedDialogEdgeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CompletedDialogEdgeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CompletedDialogEdgeFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "goalProgressHookEdgeFragment"
    },
    {
      "alias": "seenCompleted",
      "args": [
        {
          "kind": "Literal",
          "name": "popupType",
          "value": "InitiativePopupCompleted"
        }
      ],
      "kind": "ScalarField",
      "name": "seenPopup",
      "storageKey": "seenPopup(popupType:\"InitiativePopupCompleted\")"
    },
    {
      "alias": "seenIntro",
      "args": [
        {
          "kind": "Literal",
          "name": "popupType",
          "value": "InitiativePopupIntro"
        }
      ],
      "kind": "ScalarField",
      "name": "seenPopup",
      "storageKey": "seenPopup(popupType:\"InitiativePopupIntro\")"
    },
    {
      "alias": "initiative",
      "args": null,
      "concreteType": "Initiative",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "completionSuccessContent",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LearnerInitiativeEdge",
  "abstractKey": null
};

(node as any).hash = "1e5a3aa535f536300210a50ea3ce42ac";

export default node;
