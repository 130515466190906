import ChoiceButton from './ChoiceButton'
import { activityName } from '@/common/utils'
import Markdown from '@/components/Markdown'
import Pill from '@/components/lms/Pill'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/lms/Tooltip'
import { ActivityCard_assignment$key } from './__generated__/ActivityCard_assignment.graphql'
import { graphql, useFragment } from 'react-relay'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/Card'
import { BarChartBig, Goal, ListChecks, Sparkle } from 'lucide-react'
import { Button } from '@/components/Button'
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogClose,
  DialogTitle,
} from '@/components/Dialog'

const ActivityCardFragment = graphql`
  fragment ActivityCard_assignment on Assignment {
    activity {
      name
      instructions
      shortDescription
      behaviors {
        nodes {
          id
          name
          shortDescription
          longDescription
          competency {
            id
            name
            shortDescription
            longDescription
          }
        }
      }
    }
    learner {
      customer {
        competencyLabel
      }
    }
  }
`

type ActivityCardProps = {
  activityCardFragment: ActivityCard_assignment$key
  choose: () => void
  index: number
}

function ActivityCard({
  activityCardFragment,
  choose,
  index,
}: ActivityCardProps) {
  const assignment = useFragment(ActivityCardFragment, activityCardFragment)
  const activity = assignment.activity
  const name = activityName(activity)

  return (
    <Card
      className={`${index != 0 && 'opacity-70 duration-200 hover:opacity-100'}`}
    >
      <CardHeader className="space-y-6">
        <div className="items flex gap-3">
          {index === 0 && (
            <div className="inline-flex cursor-default items-center gap-1.5 rounded-md bg-flintBlue px-2 py-0.5 text-white">
              <Sparkle width={15} />
              <p className="relative top-px text-[0.65rem] uppercase leading-none tracking-widest">
                Recommended for you
              </p>
            </div>
          )}
        </div>
        <CardTitle size="sm">{name}</CardTitle>
      </CardHeader>
      <CardContent className="space-y-6">
        <div className="space-y-2">
          <div className="flex items-center gap-2">
            <Goal className="w-4 stroke-flintBlue" strokeWidth={2.5} />
            <h3 className="font-semibold">Objective</h3>
          </div>
          <p className="text-sm">{activity.shortDescription}</p>
        </div>

        <div className="space-y-2">
          <div className="flex items-center gap-2">
            <BarChartBig className="w-4 stroke-flintBlue" strokeWidth={2.5} />
            <h3 className="font-semibold">Improve These Behaviors</h3>
          </div>
          <div className="flex flex-wrap gap-2">
            {activity.behaviors.nodes.map((behavior, i) => (
              <Dialog key={i}>
                <DialogContent size="md" closeBtn>
                  <DialogHeader>
                    <DialogTitle>{behavior.name}</DialogTitle>
                  </DialogHeader>
                  <Markdown>{behavior.longDescription}</Markdown>
                  <DialogFooter>
                    <DialogClose asChild>
                      <Button size="sm">Close</Button>
                    </DialogClose>
                  </DialogFooter>
                </DialogContent>

                <TooltipProvider delayDuration={150}>
                  <Tooltip>
                    <DialogTrigger asChild>
                      <TooltipTrigger>
                        <div className="cursor-pointer">
                          <Pill>{behavior.name}</Pill>
                        </div>
                      </TooltipTrigger>
                    </DialogTrigger>
                    <TooltipContent>{behavior.shortDescription}</TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </Dialog>
            ))}
          </div>
        </div>

        <div className="grid grid-cols-2 items-center gap-3 pt-12">
          <ChoiceButton name={name} choose={choose} />
          <Dialog>
            <DialogContent size="md">
              <DialogHeader>
                <DialogTitle>{name}</DialogTitle>
              </DialogHeader>
              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <Goal className="w-4 stroke-flintBlue" strokeWidth={2.5} />
                  <h3 className="font-semibold">Objective</h3>
                </div>
                <Markdown>{activity.shortDescription}</Markdown>
              </div>
              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <ListChecks
                    className="w-4 stroke-flintBlue"
                    strokeWidth={2.5}
                  />
                  <h3 className="font-semibold">Instructions</h3>
                </div>
                <Markdown>{activity.instructions}</Markdown>
              </div>
              <DialogFooter>
                <ChoiceButton name={name} choose={choose} />
                <DialogClose>
                  <Button variant="secondary">Close</Button>
                </DialogClose>
              </DialogFooter>
            </DialogContent>
            <DialogTrigger asChild>
              <Button variant="secondary">More Information</Button>
            </DialogTrigger>
          </Dialog>
        </div>
      </CardContent>
    </Card>
  )
}

export default ActivityCard
