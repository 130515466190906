/**
 * @generated SignedSource<<d2201e12edcbaac32a8d0beebe3bd725>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PromptAnswerFragment$data = {
  readonly question: {
    readonly behavior: {
      readonly longDescription: string;
      readonly name: string;
    } | null;
    readonly prompt: string;
  };
  readonly " $fragmentType": "PromptAnswerFragment";
};
export type PromptAnswerFragment$key = {
  readonly " $data"?: PromptAnswerFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PromptAnswerFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PromptAnswerFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SurveyQuestion",
      "kind": "LinkedField",
      "name": "question",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "prompt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Skill",
          "kind": "LinkedField",
          "name": "behavior",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "longDescription",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SurveyAnswer",
  "abstractKey": null
};

(node as any).hash = "7aea89b8765ea8b69b14e5a4d781da22";

export default node;
