/**
 * @generated SignedSource<<d337d6a0150633388ef52c345a7c3e74>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompletedDialogLearnerFragment$data = {
  readonly achievementCategories: ReadonlyArray<{
    readonly achievements: ReadonlyArray<{
      readonly acknowledged: boolean;
      readonly completionDate: number | null;
    }>;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"NextActionButtonLearnerFragment" | "goalProgressHookLearnerFragment">;
  readonly " $fragmentType": "CompletedDialogLearnerFragment";
};
export type CompletedDialogLearnerFragment$key = {
  readonly " $data"?: CompletedDialogLearnerFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CompletedDialogLearnerFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CompletedDialogLearnerFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "goalProgressHookLearnerFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NextActionButtonLearnerFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnerAchievementCategory",
      "kind": "LinkedField",
      "name": "achievementCategories",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LearnerAchievement",
          "kind": "LinkedField",
          "name": "achievements",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "acknowledged",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "completionDate",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Learner",
  "abstractKey": null
};

(node as any).hash = "6a6fca6d9aeb1c35f7650e8e7b4accc0";

export default node;
