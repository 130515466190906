/**
 * @generated SignedSource<<9f8bb29fd1f07977f4157088780991d8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ActivityQuery$variables = {};
export type ActivityQuery$data = {
  readonly learner: {
    readonly availableAssignments: ReadonlyArray<{
      readonly id: string;
    }>;
    readonly currentAssignment: {
      readonly id: string;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"CurrentActivity_learner" | "SelectActivity_learner">;
  } | null;
};
export type ActivityQuery = {
  response: ActivityQuery$data;
  variables: ActivityQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dueDate",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shortDescription",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "instructions",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "longDescription",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "SkillSet",
  "kind": "LinkedField",
  "name": "competency",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/),
    (v6/*: any*/)
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Customer",
  "kind": "LinkedField",
  "name": "customer",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "competencyLabel",
      "storageKey": null
    },
    (v0/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ActivityQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Learner",
        "kind": "LinkedField",
        "name": "learner",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Assignment",
            "kind": "LinkedField",
            "name": "currentAssignment",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Assignment",
            "kind": "LinkedField",
            "name": "availableAssignments",
            "plural": true,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CurrentActivity_learner"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SelectActivity_learner"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ActivityQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Learner",
        "kind": "LinkedField",
        "name": "learner",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Assignment",
            "kind": "LinkedField",
            "name": "currentAssignment",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "choiceDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Activity",
                "kind": "LinkedField",
                "name": "activity",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "guidance",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SkillConnection",
                    "kind": "LinkedField",
                    "name": "behaviors",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Skill",
                        "kind": "LinkedField",
                        "name": "nodes",
                        "plural": true,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "notes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "generatedReasoning",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Assignment",
            "kind": "LinkedField",
            "name": "availableAssignments",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Activity",
                "kind": "LinkedField",
                "name": "activity",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v5/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SkillConnection",
                    "kind": "LinkedField",
                    "name": "behaviors",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Skill",
                        "kind": "LinkedField",
                        "name": "nodes",
                        "plural": true,
                        "selections": [
                          (v0/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Learner",
                "kind": "LinkedField",
                "name": "learner",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v0/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/),
          (v8/*: any*/),
          {
            "alias": "currentInitiative",
            "args": [
              {
                "kind": "Literal",
                "name": "membershipFilter",
                "value": {
                  "atDate": 0
                }
              }
            ],
            "concreteType": "LearnerInitiativeConnection",
            "kind": "LinkedField",
            "name": "initiatives",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              }
            ],
            "storageKey": "initiatives(membershipFilter:{\"atDate\":0})"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Treatment",
            "kind": "LinkedField",
            "name": "treatments",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Experiment",
                "kind": "LinkedField",
                "name": "experiment",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ee5e06892f968531036910a28bd73334",
    "id": null,
    "metadata": {},
    "name": "ActivityQuery",
    "operationKind": "query",
    "text": "query ActivityQuery {\n  learner {\n    currentAssignment {\n      id\n    }\n    availableAssignments {\n      id\n    }\n    ...CurrentActivity_learner\n    ...SelectActivity_learner\n    id\n  }\n}\n\nfragment ActivityCard_assignment on Assignment {\n  activity {\n    name\n    instructions\n    shortDescription\n    behaviors {\n      nodes {\n        id\n        name\n        shortDescription\n        longDescription\n        competency {\n          id\n          name\n          shortDescription\n          longDescription\n        }\n      }\n    }\n    id\n  }\n  learner {\n    customer {\n      competencyLabel\n      id\n    }\n    id\n  }\n}\n\nfragment CurrentActivity_learner on Learner {\n  id\n  currentAssignment {\n    id\n    dueDate\n    choiceDate\n    activity {\n      guidance\n      name\n      shortDescription\n      instructions\n      behaviors {\n        nodes {\n          name\n          shortDescription\n          longDescription\n          competency {\n            id\n            name\n            shortDescription\n            longDescription\n          }\n          id\n        }\n      }\n      id\n    }\n    notes\n    generatedReasoning\n  }\n  customer {\n    competencyLabel\n    id\n  }\n  currentInitiative: initiatives(membershipFilter: {atDate: 0}) {\n    totalCount\n  }\n  treatments {\n    name\n    experiment {\n      name\n      id\n    }\n    id\n  }\n}\n\nfragment SelectActivity_learner on Learner {\n  id\n  availableAssignments {\n    id\n    dueDate\n    ...ActivityCard_assignment\n  }\n  treatments {\n    name\n    experiment {\n      name\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ab3fbabb9876ae1044235365de857445";

export default node;
