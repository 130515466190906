import {
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogClose,
} from '@/components/Dialog'
import Markdown from '@/components/Markdown'
import { graphql, useFragment } from 'react-relay'
import { CompletedDialogEdgeFragment$key } from './__generated__/CompletedDialogEdgeFragment.graphql'
import { CompletedDialogLearnerFragment$key } from './__generated__/CompletedDialogLearnerFragment.graphql'
import { useGoalProgress } from './goal-progress-hook'
import { NextActionButton } from './NextActionButton'
import { AcknowledgedDialog } from '@/components/AcknowledgedDialog'

const LearnerFragment = graphql`
  fragment CompletedDialogLearnerFragment on Learner {
    ...goalProgressHookLearnerFragment
    ...NextActionButtonLearnerFragment
    achievementCategories {
      achievements {
        acknowledged
        completionDate
      }
    }
  }
`

const EdgeFragment = graphql`
  fragment CompletedDialogEdgeFragment on LearnerInitiativeEdge {
    ...goalProgressHookEdgeFragment
    seenCompleted: seenPopup(popupType: InitiativePopupCompleted)
    seenIntro: seenPopup(popupType: InitiativePopupIntro)
    initiative: node {
      id
      completionSuccessContent
    }
  }
`

const Mutation = graphql`
  mutation CompletedDialogMutation($initiative: ID!) {
    seenInitiativePopup(
      initiative: $initiative
      popupType: InitiativePopupCompleted
    ) {
      currentInitiative: initiatives(membershipFilter: { atDate: 0 }) {
        edges {
          seenCompleted: seenPopup(popupType: InitiativePopupCompleted)
          initiative: node {
            id
          }
        }
      }
    }
  }
`

type CompletedDialogProps = {
  edgeRef: CompletedDialogEdgeFragment$key
  learnerRef: CompletedDialogLearnerFragment$key
}

export function CompletedDialog({ edgeRef, learnerRef }: CompletedDialogProps) {
  const data = useFragment(EdgeFragment, edgeRef)
  const learner = useFragment(LearnerFragment, learnerRef)
  const { totalSteps, stepsCompleted } = useGoalProgress(learner, data)

  const hasUnacknowledgedAchievement = !!learner.achievementCategories
    .flatMap((x) => x.achievements)
    .find((x) => !x.acknowledged && x.completionDate)

  const content = data.initiative.completionSuccessContent

  // wait to show this dialog until the user has acknowledged all their achievements
  if (
    hasUnacknowledgedAchievement ||
    content === '' ||
    !data.seenIntro ||
    stepsCompleted < totalSteps
  )
    return null

  return (
    <AcknowledgedDialog
      Mutation={Mutation}
      variables={{ initiative: data.initiative.id }}
      beenSeen={data.seenCompleted}
    >
      <DialogContent size="md" closeBtn confetti>
        <DialogHeader>
          <DialogTitle>Congratulations</DialogTitle>
        </DialogHeader>
        <div>
          <Markdown>{content}</Markdown>
        </div>

        <DialogFooter className="flex justify-end">
          <DialogClose asChild>
            <NextActionButton learnerRef={learner} />
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </AcknowledgedDialog>
  )
}
