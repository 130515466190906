/**
 * @generated SignedSource<<afcb384435d8fedefe7c42c06368c1ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GeneralSurveyCreateMutation$variables = {
  surveyAssignment: string;
};
export type GeneralSurveyCreateMutation$data = {
  readonly createSurveyResponse: {
    readonly learner: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"GeneralSurveyFragment">;
    };
  } | null;
};
export type GeneralSurveyCreateMutation = {
  response: GeneralSurveyCreateMutation$data;
  variables: GeneralSurveyCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "surveyAssignment"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "surveyAssignment",
    "variableName": "surveyAssignment"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "instructions",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Survey",
  "kind": "LinkedField",
  "name": "survey",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GeneralSurveyCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SurveyResponse",
        "kind": "LinkedField",
        "name": "createSurveyResponse",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Learner",
            "kind": "LinkedField",
            "name": "learner",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "GeneralSurveyFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GeneralSurveyCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SurveyResponse",
        "kind": "LinkedField",
        "name": "createSurveyResponse",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Learner",
            "kind": "LinkedField",
            "name": "learner",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SurveyResponse",
                "kind": "LinkedField",
                "name": "pendingSurvey",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SurveyResponseSection",
                    "kind": "LinkedField",
                    "name": "sections",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SurveyAnswer",
                        "kind": "LinkedField",
                        "name": "answers",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "ordinal",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SurveyQuestion",
                            "kind": "LinkedField",
                            "name": "question",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Skill",
                                "kind": "LinkedField",
                                "name": "behavior",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "longDescription",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "prompt",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "choiceCount",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "choiceLabels",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "multipleResponses",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "lastIsNoneOfTheAbove",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "choice",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "choices",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "text",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "previousChoice",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Learner",
                            "kind": "LinkedField",
                            "name": "learnerDescribed",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "email",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "fullName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "lmsUserID",
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SurveyAssignment",
                    "kind": "LinkedField",
                    "name": "assignment",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "showPreviousChoices",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SurveyAssignment",
                "kind": "LinkedField",
                "name": "surveyAwaitingResponse",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3d04c24c7b27203c3b3c0e9a1b0596b7",
    "id": null,
    "metadata": {},
    "name": "GeneralSurveyCreateMutation",
    "operationKind": "mutation",
    "text": "mutation GeneralSurveyCreateMutation(\n  $surveyAssignment: ID!\n) {\n  createSurveyResponse(surveyAssignment: $surveyAssignment) {\n    learner {\n      id\n      ...GeneralSurveyFragment\n    }\n    id\n  }\n}\n\nfragment GeneralSurveyFragment on Learner {\n  pendingSurvey {\n    id\n    survey {\n      id\n      title\n      instructions\n    }\n    sections {\n      id\n      title\n      instructions\n      answers {\n        id\n        ordinal\n        question {\n          id\n          behavior {\n            id\n          }\n        }\n        ...QuestionCardFragment\n        learnerDescribed {\n          ...QuestionCardLearnerFragment\n          id\n        }\n      }\n    }\n    assignment {\n      showPreviousChoices\n      id\n    }\n    ...completedAnswersFragment\n  }\n  surveyAwaitingResponse {\n    id\n    survey {\n      id\n      title\n      instructions\n    }\n  }\n}\n\nfragment PromptAnswerFragment on SurveyAnswer {\n  question {\n    prompt\n    behavior {\n      name\n      longDescription\n      id\n    }\n    id\n  }\n}\n\nfragment PromptLearnerFragment on Learner {\n  ...TemplateLearnerFragment\n}\n\nfragment QuestionCardFragment on SurveyAnswer {\n  id\n  ...PromptAnswerFragment\n  question {\n    id\n    choiceCount\n    choiceLabels\n    multipleResponses\n    lastIsNoneOfTheAbove\n  }\n  choice\n  choices\n  text\n  ordinal\n  previousChoice\n}\n\nfragment QuestionCardLearnerFragment on Learner {\n  ...PromptLearnerFragment\n}\n\nfragment TemplateLearnerFragment on Learner {\n  email\n  fullName\n  lmsUserID\n}\n\nfragment completedAnswersFragment on SurveyResponse {\n  sections {\n    answers {\n      id\n      question {\n        choiceCount\n        id\n      }\n      choice\n      choices\n      text\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "44b46c54c058cfd5e3eb21c2187d6ba2";

export default node;
