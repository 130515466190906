import { useCallback, useEffect, useState } from 'react'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/Card'
import { Link } from '@tanstack/react-router'
import { Button } from '@/components/Button'
import { useGoalProgress } from './goal-progress-hook'
import { goalProgressHookLearnerFragment$key } from './__generated__/goalProgressHookLearnerFragment.graphql'
import { goalProgressHookEdgeFragment$key } from './__generated__/goalProgressHookEdgeFragment.graphql'

interface ProgressCardProps {
  learnerRef: goalProgressHookLearnerFragment$key
  edgeRef: goalProgressHookEdgeFragment$key
  animated: boolean
  hidden: boolean
  showMore?: boolean
}

export function ProgressCard({
  learnerRef,
  edgeRef,
  animated,
  hidden,
  showMore = true,
}: ProgressCardProps) {
  const { totalSteps, stepsCompleted, hasPathway } = useGoalProgress(
    learnerRef,
    edgeRef
  )

  const calculateProgress = useCallback(
    (steps: number) => {
      return (steps / (totalSteps || 1)) * 100
    },
    [totalSteps]
  )

  const [barWidth, setBarWidth] = useState(
    calculateProgress(animated ? stepsCompleted - 1 : stepsCompleted)
  )

  useEffect(() => {
    if (animated) {
      setTimeout(() => {
        setBarWidth(calculateProgress(stepsCompleted))
      }, 200)
    }
  }, [animated, calculateProgress, stepsCompleted])

  if (!totalSteps) return null

  return (
    <Card
      className={`transition-opacity duration-500 ${hidden ? 'lg:opacity-0' : 'lg:opacity-100'}`}
      animated={animated}
    >
      <CardHeader>
        <CardTitle size="sm">Progress</CardTitle>
      </CardHeader>
      <CardContent className="text-sm">
        <div className="relative mb-12 h-2">
          <div className="absolute h-full w-full rounded-full bg-slate-200"></div>
          <div
            className="absolute h-full rounded-full bg-gradient-to-r from-[#7192b6] to-flintBlue"
            style={{
              width: `${barWidth > 100 ? 100 : barWidth}%`,
              animationDuration: '1500ms',
              animationDelay: '1s',
              transitionDuration: '1500ms',
              transitionDelay: '1s',
            }}
          ></div>
          <div className="relative top-4 text-right font-medium">
            {stepsCompleted} / {totalSteps} activities completed
          </div>
        </div>
        {hasPathway && showMore ? (
          <Button>
            <Link to="/lms/pathway">View More</Link>
          </Button>
        ) : null}
      </CardContent>
    </Card>
  )
}
