/**
 * @generated SignedSource<<1992bc67c78fe5de512a362ad017a56c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GeneralSurveyFragment$data = {
  readonly pendingSurvey: {
    readonly assignment: {
      readonly showPreviousChoices: boolean;
    };
    readonly id: string;
    readonly sections: ReadonlyArray<{
      readonly answers: ReadonlyArray<{
        readonly id: string;
        readonly learnerDescribed: {
          readonly " $fragmentSpreads": FragmentRefs<"QuestionCardLearnerFragment">;
        } | null;
        readonly ordinal: number;
        readonly question: {
          readonly behavior: {
            readonly id: string;
          } | null;
          readonly id: string;
        };
        readonly " $fragmentSpreads": FragmentRefs<"QuestionCardFragment">;
      }>;
      readonly id: string;
      readonly instructions: string;
      readonly title: string;
    }>;
    readonly survey: {
      readonly id: string;
      readonly instructions: string;
      readonly title: string;
    };
    readonly " $fragmentSpreads": FragmentRefs<"completedAnswersFragment">;
  } | null;
  readonly surveyAwaitingResponse: {
    readonly id: string;
    readonly survey: {
      readonly id: string;
      readonly instructions: string;
      readonly title: string;
    };
  } | null;
  readonly " $fragmentType": "GeneralSurveyFragment";
};
export type GeneralSurveyFragment$key = {
  readonly " $data"?: GeneralSurveyFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"GeneralSurveyFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "instructions",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Survey",
  "kind": "LinkedField",
  "name": "survey",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GeneralSurveyFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SurveyResponse",
      "kind": "LinkedField",
      "name": "pendingSurvey",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "SurveyResponseSection",
          "kind": "LinkedField",
          "name": "sections",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "SurveyAnswer",
              "kind": "LinkedField",
              "name": "answers",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "ordinal",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SurveyQuestion",
                  "kind": "LinkedField",
                  "name": "question",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Skill",
                      "kind": "LinkedField",
                      "name": "behavior",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "QuestionCardFragment"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Learner",
                  "kind": "LinkedField",
                  "name": "learnerDescribed",
                  "plural": false,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "QuestionCardLearnerFragment"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SurveyAssignment",
          "kind": "LinkedField",
          "name": "assignment",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "showPreviousChoices",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "completedAnswersFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SurveyAssignment",
      "kind": "LinkedField",
      "name": "surveyAwaitingResponse",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v3/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Learner",
  "abstractKey": null
};
})();

(node as any).hash = "b36f66308a3eed2e7565547a45ea6fc0";

export default node;
