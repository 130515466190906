import { useState } from 'react'
import { graphql, useFragment } from 'react-relay'
import { InitiativeSummaryCard } from './InitiativeSummaryCard'
import { BehaviorsCard } from './BehaviorsCard'
import { CompletedActivitiesCard } from './CompletedActivitiesCard'
import { ProgressCard } from './ProgressCard'
import { Banner } from './Banner'
import { InitiativeEdgeFragment$key } from './__generated__/InitiativeEdgeFragment.graphql'
import { InitiativeLearnerFragment$key } from './__generated__/InitiativeLearnerFragment.graphql'

const EdgeFragment = graphql`
  fragment InitiativeEdgeFragment on LearnerInitiativeEdge {
    ...InitiativeSummaryCardFragment
    ...CompletedActivitiesCardFragment
    ...BehaviorsCardEdgeFragment
    ...goalProgressHookEdgeFragment
  }
`

const LearnerFragment = graphql`
  fragment InitiativeLearnerFragment on Learner {
    currentInitiative: initiatives(membershipFilter: { atDate: 0 }) {
      edges {
        ...InitiativeEdgeFragment
      }
    }
    ...goalProgressHookLearnerFragment
    ...BehaviorsCardLearnerFragment
    ...NextActionButtonLearnerFragment
  }
`

type InitiativeProps = {
  edgeRef: InitiativeEdgeFragment$key
  learnerRef: InitiativeLearnerFragment$key
  learnerIsCurrent: boolean
  activityCompleted?: boolean
  activityIncompleted?: boolean
  showInteractivity?: boolean
}

export default function Initiative({
  edgeRef,
  learnerRef,
  activityCompleted,
  activityIncompleted,
  showInteractivity,
  learnerIsCurrent,
}: InitiativeProps) {
  const edge = useFragment(EdgeFragment, edgeRef)
  const learner = useFragment(LearnerFragment, learnerRef)
  const [focusedBox, setFocusedBox] = useState<
    'none' | 'initiative' | 'completed-activities' | 'pathway' | 'behaviors'
  >('none')

  const [showBanner, setShowBanner] = useState(
    activityCompleted || activityIncompleted
  )

  return (
    <div className="flex grid-cols-3 flex-col gap-6 lg:grid">
      {showBanner && showInteractivity ? (
        <Banner
          onClose={() => setShowBanner(false)}
          activityCompleted={activityCompleted || false}
        />
      ) : null}
      <InitiativeSummaryCard
        edgeRef={edge}
        learnerRef={learner}
        hidden={focusedBox !== 'none' && focusedBox !== 'initiative'}
      />
      <CompletedActivitiesCard
        edgeRef={edge}
        learnerIsCurrent={learnerIsCurrent}
        animated={activityCompleted || false}
        focused={focusedBox === 'completed-activities'}
        onFocus={() => setFocusedBox('completed-activities')}
        onBlur={() => setFocusedBox('none')}
      />
      <ProgressCard
        learnerRef={learner}
        edgeRef={edge}
        animated={activityCompleted || false}
        hidden={focusedBox !== 'none' && focusedBox !== 'pathway'}
        showMore={learnerIsCurrent}
      />
      <BehaviorsCard
        edgeRef={edge}
        learner={learner}
        hidden={focusedBox !== 'none' && focusedBox !== 'behaviors'}
      />
    </div>
  )
}
