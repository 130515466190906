/**
 * @generated SignedSource<<e417ebf94e86228a7c77d9d080393ad9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InitiativeEdgeFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BehaviorsCardEdgeFragment" | "CompletedActivitiesCardFragment" | "InitiativeSummaryCardFragment" | "goalProgressHookEdgeFragment">;
  readonly " $fragmentType": "InitiativeEdgeFragment";
};
export type InitiativeEdgeFragment$key = {
  readonly " $data"?: InitiativeEdgeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"InitiativeEdgeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InitiativeEdgeFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InitiativeSummaryCardFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CompletedActivitiesCardFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BehaviorsCardEdgeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "goalProgressHookEdgeFragment"
    }
  ],
  "type": "LearnerInitiativeEdge",
  "abstractKey": null
};

(node as any).hash = "c8ba0b0477f4fe46b32722c38e289b20";

export default node;
