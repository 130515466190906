/**
 * @generated SignedSource<<1efd74d7a6d557db8fc95da412a4bd72>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EndDialogLearnerFragment$data = {
  readonly achievementCategories: ReadonlyArray<{
    readonly achievements: ReadonlyArray<{
      readonly acknowledged: boolean;
      readonly completionDate: number | null;
    }>;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"NextActionButtonLearnerFragment" | "goalProgressHookLearnerFragment">;
  readonly " $fragmentType": "EndDialogLearnerFragment";
};
export type EndDialogLearnerFragment$key = {
  readonly " $data"?: EndDialogLearnerFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EndDialogLearnerFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EndDialogLearnerFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "goalProgressHookLearnerFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NextActionButtonLearnerFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnerAchievementCategory",
      "kind": "LinkedField",
      "name": "achievementCategories",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LearnerAchievement",
          "kind": "LinkedField",
          "name": "achievements",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "acknowledged",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "completionDate",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Learner",
  "abstractKey": null
};

(node as any).hash = "faee42eaaf6dc72ad9059a326a5afe22";

export default node;
