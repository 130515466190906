import { LMSRedirectQuery } from './__generated__/LMSRedirectQuery.graphql'
import { Navigate, useSearch } from '@tanstack/react-router'
import { graphql, useLazyLoadQuery } from 'react-relay'

function LMSRedirect() {
  const LMSRedirectQuery = graphql`
    query LMSRedirectQuery {
      learner {
        seenWelcome
        receivingActivities
        directReports {
          nodes {
            id
            pendingSurveyFromManager {
              id
            }
            surveyAwaitingResponseFromManager {
              id
            }
            treatments {
              id
              treatmentType
            }
          }
        }
        currentAssignment {
          id
        }
        pendingSurvey {
          id
        }
        surveyAwaitingResponse {
          id
        }
        availableAssignments {
          id
        }
        initiatives(membershipFilter: { atDate: 0 }) {
          edges {
            seenIntro: seenPopup(popupType: InitiativePopupIntro)
            initiative: node {
              id
              showChoices
              choices {
                totalCount
              }
            }
          }
        }
        pastInitiatives: initiatives(membershipFilter: { beforeDate: 0 }) {
          edges {
            seenEnd: seenPopup(popupType: InitiativePopupEnd)
            endDate
            initiative: node {
              id
              completionDoneContent
            }
          }
        }
        needsToProvideEmail
        customer {
          id
          name
        }
      }
    }
  `
  const data = useLazyLoadQuery<LMSRedirectQuery>(
    LMSRedirectQuery,
    {},
    { fetchPolicy: 'network-only' }
  )
  const learner = data?.learner
  const currentInitiative = learner?.initiatives?.edges[0]
  const hasChoices =
    currentInitiative?.initiative?.showChoices &&
    currentInitiative?.initiative?.choices?.totalCount > 0
  const initialQueryParams = useSearch({ from: '/lms' })

  // when you achieve the goal, you are redirected to the initiative page
  // so we don't need to deal with that here
  // note that we only factor in the intro of the current initiative
  const currentInitiativePendingPopup =
    currentInitiative?.seenIntro === false &&
    !currentInitiative?.initiative.showChoices

  const mostRecentPastInitiative = [
    ...(learner?.pastInitiatives?.edges || []),
  ].sort((a, b) => b.endDate - a.endDate)[0]
  const pastInitiativesPendingPopup =
    mostRecentPastInitiative?.seenEnd === false &&
    (mostRecentPastInitiative?.initiative.completionDoneContent || '') !== ''

  const initiativePopupPending =
    currentInitiativePendingPopup || pastInitiativesPendingPopup

  const directReports =
    learner?.directReports.nodes.filter(
      (l) => !l.treatments.some((t) => t.treatmentType === 'HeldOut')
    ) || []
  const oneOnOnes = directReports.filter(
    (l) => l.pendingSurveyFromManager || l.surveyAwaitingResponseFromManager
  )

  const routeTo = !learner
    ? 'login'
    : !learner.seenWelcome && learner.receivingActivities
      ? 'orientation'
      : learner.needsToProvideEmail
        ? 'provide-email'
        : oneOnOnes.length
          ? 'my-learners'
          : learner.pendingSurvey || learner.surveyAwaitingResponse
            ? 'general-survey'
            : hasChoices && learner.receivingActivities
              ? 'initiatives/select'
              : initiativePopupPending && initialQueryParams.complete === null
                ? 'initiatives'
                : learner.currentAssignment ||
                    learner.availableAssignments.length
                  ? 'activity'
                  : directReports.length
                    ? 'my-learners'
                    : 'waiting'

  const finalQueryParams =
    routeTo === 'activity' ? { complete: initialQueryParams.complete } : {}

  return <Navigate to={`/lms/${routeTo}`} search={finalQueryParams} />
}

export default LMSRedirect
