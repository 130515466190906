import { graphql, useFragment } from 'react-relay'
import { TemplateLearnerFragment$key } from './__generated__/TemplateLearnerFragment.graphql'

const LearnerFragment = graphql`
  fragment TemplateLearnerFragment on Learner {
    email
    fullName
    lmsUserID
  }
`

type TemplateProps = {
  text: string
  learnerRef?: TemplateLearnerFragment$key | null
  underline?: boolean
}

export function Template({ text, learnerRef, underline }: TemplateProps) {
  const learner = useFragment(LearnerFragment, learnerRef)
  const name =
    learner && (learner.fullName || learner.email || learner.lmsUserID)
  const fragments = text.split('#{learner}')
  return text.includes('#{learner}') && name ? (
    <>
      {fragments.slice(0, -1).map((f) => (
        <>
          {f}
          <var {...(underline ? { className: 'underline' } : {})}>{name}</var>
        </>
      ))}
      {fragments[fragments.length - 1]}
    </>
  ) : (
    text
  )
}
