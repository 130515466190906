import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/Card'
import { useFragment, graphql } from 'react-relay'
import { InitiativeSummaryCardFragment$key } from './__generated__/InitiativeSummaryCardFragment.graphql'
import { CalendarCheck, CalendarX } from 'lucide-react'
import Markdown from '@/components/Markdown'
import { NextActionButton } from './NextActionButton'
import { NextActionButtonLearnerFragment$key } from './__generated__/NextActionButtonLearnerFragment.graphql'

const EdgeFragment = graphql`
  fragment InitiativeSummaryCardFragment on LearnerInitiativeEdge {
    initiative: node {
      name
      description
    }
    startDate
    endDate
  }
`

interface InitiativeSummaryCardProps {
  edgeRef: InitiativeSummaryCardFragment$key
  hidden: boolean
  learnerRef: NextActionButtonLearnerFragment$key
}

export function InitiativeSummaryCard({
  edgeRef,
  hidden,
  learnerRef,
}: InitiativeSummaryCardProps) {
  const data = useFragment(EdgeFragment, edgeRef)
  const startDate = new Date(data.startDate)
  const endDate = new Date(data.endDate)

  return (
    <Card
      className={`transition-opacity duration-500 ${hidden ? 'lg:opacity-0' : 'lg:opacity-100'}`}
    >
      <CardHeader>
        <CardTitle size="sm">{data.initiative.name}</CardTitle>
        <CardDescription>
          <Markdown>{data.initiative.description}</Markdown>
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="flex gap-6 text-sm">
          <div className="flex items-center gap-1.5">
            <CalendarCheck size={16} className="text-flintBlue" />
            <p>
              <span className="font-medium">Start: </span>
              <span>{startDate.toLocaleDateString()}</span>
            </p>
          </div>
          <div className="flex items-center gap-1.5">
            <CalendarX size={16} className="text-flintBlue" />
            <p>
              <span className="font-medium">End: </span>
              <span>{data.endDate ? endDate.toLocaleDateString() : ''}</span>
            </p>
          </div>
        </div>
      </CardContent>
      <CardFooter>
        <NextActionButton learnerRef={learnerRef} pulse />
      </CardFooter>
    </Card>
  )
}
