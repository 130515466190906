import { Dialog } from '@/components/Dialog'
import { GraphQLTaggedNode, useMutation } from 'react-relay'
import { useBlocker } from '@tanstack/react-router'
import { useEffect, useState, useCallback, useRef } from 'react'
import { MutationParameters } from 'relay-runtime'

type AcknowledgedDialogProps<TMutation extends MutationParameters> = {
  Mutation: GraphQLTaggedNode
  variables: TMutation['variables']
  children: React.ReactNode
  beenSeen: boolean
  delay?: number
}

export function AcknowledgedDialog<TMutation extends MutationParameters>({
  Mutation,
  variables,
  children,
  beenSeen,
  delay = 2000,
}: AcknowledgedDialogProps<TMutation>) {
  const [mutate] = useMutation(Mutation)
  const [open, setOpen] = useState(!beenSeen)

  const marked = useRef(false)

  const markSeenPromise = useCallback(() => {
    if (marked.current) return Promise.resolve(true)
    marked.current = true
    return new Promise((resolve) => {
      mutate({
        variables,
        onCompleted: () => {
          resolve(true)
        },
        onError: () => {
          resolve(true)
        },
      })
    })
  }, [mutate, variables, marked])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!open) return
      markSeenPromise()
    }, delay)
    return () => clearTimeout(timeout)
  }, [delay, markSeenPromise, open])

  // if the user clicks a link quickly, we want to count that as a view
  // unfortuantely, this is not a perfect solution because tanstack also
  // add a stupid popup if the user refreshes the page or closes the tab.
  // If they every allow getting rid of that we can fix this.
  //
  // Another fix might have been to make the link fire the mutation and THEN navigate,
  // but that would mean the links are not really links anymore so control click, hover, etc. would not work.
  useBlocker({
    blockerFn: markSeenPromise,
    condition: !marked.current && open,
  })

  const onClose = () => {
    markSeenPromise() // fire and forget
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onOpenChange={(newOpen) => (newOpen ? setOpen(newOpen) : onClose())}
    >
      {children}
    </Dialog>
  )
}
