import {
  AnyRoute,
  createRoute,
  lazyRouteComponent,
} from '@tanstack/react-router'

// Weirdly you have to keep getParentRoute generic because Tanstack uses the
//   type of the parent route as part of the type of the child route
export default function getAdminRoutes<T extends AnyRoute>(
  getParentRoute: () => T
) {
  const achievementRoute = createRoute({
    getParentRoute,
    path: '/admin/customer/$customerID/achievement-category/$categoryID/achievement/$achievementID',
    component: lazyRouteComponent(() => import('@/routes/admin/Achievement')),
  })
  const achievementCategoriesRoute = createRoute({
    getParentRoute,
    path: '/admin/customer/$customerID/achievement-categories',
    component: lazyRouteComponent(
      () => import('@/routes/admin/AchievementCategories')
    ),
  })
  const achievementCategoryRoute = createRoute({
    getParentRoute,
    path: '/admin/customer/$customerID/achievement-category/$categoryID',
    component: lazyRouteComponent(
      () => import('@/routes/admin/AchievementCategory')
    ),
  })
  const activityFamiliesRoute = createRoute({
    getParentRoute,
    path: '/admin/activity-families',
    component: lazyRouteComponent(
      () => import('@/routes/admin/ActivityFamilies')
    ),
  })
  const activityFamilyRoute = createRoute({
    getParentRoute,
    path: '/admin/activity-family/$familyID',
    component: lazyRouteComponent(
      () => import('@/routes/admin/ActivityFamily')
    ),
  })
  const activitiesRoute = createRoute({
    getParentRoute,
    path: '/admin/activities',
    component: lazyRouteComponent(() => import('@/routes/admin/Activities')),
  })
  const activityRoute = createRoute({
    getParentRoute,
    path: '/admin/activity/$activityID',
    component: lazyRouteComponent(() => import('@/routes/admin/Activity')),
  })
  const adminRoute = createRoute({
    getParentRoute,
    path: '/admin',
    component: lazyRouteComponent(() => import('@/routes/admin/CustomerList')),
  })
  const areaRoute = createRoute({
    getParentRoute,
    path: '/admin/area/$areaID',
    component: lazyRouteComponent(() => import('@/routes/admin/Area')),
  })
  const behaviorRoute = createRoute({
    getParentRoute,
    path: '/admin/behavior/$behaviorID',
    component: lazyRouteComponent(() => import('@/routes/admin/Behavior')),
  })
  const competencyRoute = createRoute({
    getParentRoute,
    path: '/admin/competency/$competencyID',
    component: lazyRouteComponent(() => import('@/routes/admin/Competency')),
  })
  const customerRoute = createRoute({
    getParentRoute,
    path: '/admin/customer/$customerID',
    component: lazyRouteComponent(() => import('@/routes/admin/Customer')),
  })
  const customerRelaunchRoute = createRoute({
    getParentRoute,
    path: '/admin/customer/$customerID/relaunch',
    component: lazyRouteComponent(
      () => import('@/routes/admin/CustomerRelaunch')
    ),
  })
  const customerSurveyRoute = createRoute({
    getParentRoute,
    path: '/admin/customer/$customerID/survey/$surveyAssignmentID',
    component: lazyRouteComponent(
      () => import('@/routes/admin/CustomerSurvey')
    ),
  })
  const customerSurveysRoute = createRoute({
    getParentRoute,
    path: '/admin/customer/$customerID/surveys',
    component: lazyRouteComponent(
      () => import('@/routes/admin/CustomerSurveys')
    ),
  })
  const customersRoute = createRoute({
    getParentRoute,
    path: '/admin/customers',
    component: lazyRouteComponent(() => import('@/routes/admin/CustomerList')),
  })
  const emailTemplateRoute = createRoute({
    getParentRoute,
    path: '/admin/email-template/$emailTemplateID',
    component: lazyRouteComponent(() => import('@/routes/admin/EmailTemplate')),
  })
  const emailTemplatesRoute = createRoute({
    getParentRoute,
    path: '/admin/email-templates',
    component: lazyRouteComponent(
      () => import('@/routes/admin/EmailTemplates')
    ),
  })
  const experimentsRoute = createRoute({
    getParentRoute,
    path: '/admin/customer/$customerID/experiments',
    component: lazyRouteComponent(() => import('@/routes/admin/Experiments')),
  })
  const groupRoute = createRoute({
    getParentRoute,
    path: '/admin/customer/$customerID/group/$groupID',
    component: lazyRouteComponent(() => import('@/routes/admin/Group')),
  })
  const groupsRoute = createRoute({
    getParentRoute,
    path: '/admin/customer/$customerID/groups',
    component: lazyRouteComponent(() => import('@/routes/admin/Groups')),
  })
  const initiativeRoute = createRoute({
    getParentRoute,
    path: '/admin/customer/$customerID/initiative/$initiativeID',
    component: lazyRouteComponent(
      () => import('@/routes/admin/Initiative/Initiative')
    ),
  })
  const initiativesRoute = createRoute({
    getParentRoute,
    path: '/admin/customer/$customerID/initiatives',
    component: lazyRouteComponent(
      () => import('@/routes/admin/Initiatives/Initiatives')
    ),
  })
  const learnersRoute = createRoute({
    getParentRoute,
    path: '/admin/customer/$customerID/learners',
    component: lazyRouteComponent(() => import('@/routes/admin/Learners')),
  })
  const learnersCreateRoute = createRoute({
    getParentRoute,
    path: '/admin/customer/$customerID/learners/new',
    component: lazyRouteComponent(
      () => import('@/routes/admin/LearnersCreate')
    ),
  })
  const learnerRoute = createRoute({
    getParentRoute,
    path: '/admin/customer/$customerID/learner/$learnerID',
    component: lazyRouteComponent(() => import('@/routes/admin/Learner')),
  })
  const logicalGroupRoute = createRoute({
    getParentRoute,
    path: '/admin/customer/$customerID/logical-group/$groupID',
    component: lazyRouteComponent(() => import('@/routes/admin/LogicalGroup')),
  })
  const logicalGroupsRoute = createRoute({
    getParentRoute,
    path: '/admin/customer/$customerID/logical-groups',
    component: lazyRouteComponent(() => import('@/routes/admin/LogicalGroups')),
  })
  const customBehaviorContents = createRoute({
    getParentRoute,
    path: '/admin/customer/$customerID/custom-behavior-contents',
    component: lazyRouteComponent(
      () => import('@/routes/admin/CustomBehaviorContents')
    ),
  })
  const customBehaviorContent = createRoute({
    getParentRoute,
    path: '/admin/customer/$customerID/custom-behavior-content/$customBehaviorContentID',
    component: lazyRouteComponent(
      () => import('@/routes/admin/CustomBehaviorContent')
    ),
  })
  const librariesRoute = createRoute({
    getParentRoute,
    path: '/admin/libraries',
    component: lazyRouteComponent(() => import('@/routes/admin/Libraries')),
  })
  const libraryRoute = createRoute({
    getParentRoute,
    path: '/admin/library/$libraryID',
    component: lazyRouteComponent(() => import('@/routes/admin/Library')),
  })
  const pathwayRoute = createRoute({
    getParentRoute,
    path: '/admin/pathway/$pathwayID',
    component: lazyRouteComponent(() => import('@/routes/admin/Pathway')),
  })
  const pathwaysRoute = createRoute({
    getParentRoute,
    path: '/admin/pathways',
    component: lazyRouteComponent(() => import('@/routes/admin/Pathways')),
  })
  const practicesRoute = createRoute({
    getParentRoute,
    path: '/admin/practices',
    component: lazyRouteComponent(() => import('@/routes/admin/Practices')),
  })
  const practiceRoute = createRoute({
    getParentRoute,
    path: '/admin/practice/$practiceID',
    component: lazyRouteComponent(() => import('@/routes/admin/Practice')),
  })
  const promptRoute = createRoute({
    getParentRoute,
    path: '/admin/prompt/$promptID',
    component: lazyRouteComponent(() => import('@/routes/admin/Prompt')),
  })
  const promptsRoute = createRoute({
    getParentRoute,
    path: '/admin/prompts',
    component: lazyRouteComponent(() => import('@/routes/admin/Prompts')),
  })
  const promptCreateRoute = createRoute({
    getParentRoute,
    path: '/admin/prompt/new',
    component: lazyRouteComponent(() => import('@/routes/admin/PromptCreate')),
  })
  const surveyRoute = createRoute({
    getParentRoute,
    path: '/admin/survey/$surveyID',
    component: lazyRouteComponent(() => import('@/routes/admin/Survey')),
  })
  const surveySectionRoute = createRoute({
    getParentRoute,
    path: '/admin/survey-section/$sectionID',
    component: lazyRouteComponent(() => import('@/routes/admin/SurveySection')),
  })
  const surveyQuestionRoute = createRoute({
    getParentRoute,
    path: '/admin/survey-question/$questionID',
    component: lazyRouteComponent(
      () => import('@/routes/admin/SurveyQuestion')
    ),
  })
  const surveysRoute = createRoute({
    getParentRoute,
    path: '/admin/surveys',
    component: lazyRouteComponent(() => import('@/routes/admin/Surveys')),
  })
  const updateAllActivitiesRoute = createRoute({
    getParentRoute,
    path: '/admin/update-all-activities',
    component: lazyRouteComponent(
      () => import('@/routes/admin/UpdateAllActivities')
    ),
  })

  return [
    achievementRoute,
    achievementCategoriesRoute,
    achievementCategoryRoute,
    activitiesRoute,
    activityFamiliesRoute,
    activityFamilyRoute,
    activityRoute,
    adminRoute,
    areaRoute,
    behaviorRoute,
    competencyRoute,
    customerRelaunchRoute,
    customerRoute,
    customerSurveyRoute,
    customerSurveysRoute,
    customersRoute,
    emailTemplateRoute,
    emailTemplatesRoute,
    experimentsRoute,
    groupRoute,
    groupsRoute,
    initiativeRoute,
    initiativesRoute,
    learnerRoute,
    learnersRoute,
    learnersCreateRoute,
    customBehaviorContents,
    customBehaviorContent,
    librariesRoute,
    libraryRoute,
    logicalGroupRoute,
    logicalGroupsRoute,
    pathwayRoute,
    pathwaysRoute,
    practiceRoute,
    practicesRoute,
    promptRoute,
    promptsRoute,
    promptCreateRoute,
    surveyRoute,
    surveySectionRoute,
    surveyQuestionRoute,
    surveysRoute,
    updateAllActivitiesRoute,
  ]
}
