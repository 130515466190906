/**
 * @generated SignedSource<<d56a9cce639b3af2fe27b4cc23f9ef40>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InitiativeLearnerFragment$data = {
  readonly currentInitiative: {
    readonly edges: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"InitiativeEdgeFragment">;
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"BehaviorsCardLearnerFragment" | "NextActionButtonLearnerFragment" | "goalProgressHookLearnerFragment">;
  readonly " $fragmentType": "InitiativeLearnerFragment";
};
export type InitiativeLearnerFragment$key = {
  readonly " $data"?: InitiativeLearnerFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"InitiativeLearnerFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InitiativeLearnerFragment",
  "selections": [
    {
      "alias": "currentInitiative",
      "args": [
        {
          "kind": "Literal",
          "name": "membershipFilter",
          "value": {
            "atDate": 0
          }
        }
      ],
      "concreteType": "LearnerInitiativeConnection",
      "kind": "LinkedField",
      "name": "initiatives",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LearnerInitiativeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "InitiativeEdgeFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "initiatives(membershipFilter:{\"atDate\":0})"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "goalProgressHookLearnerFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BehaviorsCardLearnerFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NextActionButtonLearnerFragment"
    }
  ],
  "type": "Learner",
  "abstractKey": null
};

(node as any).hash = "a7d86ba4df2b3a419f86f025087b677b";

export default node;
