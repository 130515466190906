import { atcb_action } from 'add-to-calendar-button'
import { activityName } from '@/common/utils'
import Markdown from '@/components/Markdown'
import Pill from '@/components/lms/Pill'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/lms/Tooltip'
import { format } from 'date-fns'
import type { ChangeEvent, ReactNode } from 'react'
import { useCallback, useContext, useEffect, useState } from 'react'
import InteractionProvider, {
  InteractionContext,
} from '@/components/Interaction'
import { graphql, useFragment, useMutation } from 'react-relay'
import { useNavigate, useRouter } from '@tanstack/react-router'
import { Card, CardContent } from '@/components/Card'
import {
  BarChartBig,
  CalendarCheck,
  CalendarPlus,
  Goal,
  Home,
  LifeBuoy,
  ListChecks,
  PenSquare,
  Sparkle,
} from 'lucide-react'
import Survey, { Feedback } from './Survey'
import { Hero, HeroSubtitle, HeroTitle } from '@/components/lms/Hero'
import Waiting from '../Waiting'
import {
  CurrentActivity_learner$data,
  CurrentActivity_learner$key,
} from './__generated__/CurrentActivity_learner.graphql'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/Dialog'
import { Button } from '@/components/Button'
import { Apple, GoogleCalendar, Microsoft365 } from '@/components/Icons'
import {
  CardTabs,
  CardTabsContent,
  CardTabsList,
  CardTabsTrigger,
} from '@/components/CardTabs'

const Fragment = graphql`
  fragment CurrentActivity_learner on Learner {
    id
    currentAssignment {
      id
      dueDate
      choiceDate
      activity {
        guidance
        name
        shortDescription
        instructions
        behaviors {
          nodes {
            name
            shortDescription
            longDescription
            competency {
              id
              name
              shortDescription
              longDescription
            }
          }
        }
      }
      notes
      generatedReasoning
    }
    customer {
      competencyLabel
    }
    currentInitiative: initiatives(membershipFilter: { atDate: 0 }) {
      totalCount
    }
    treatments {
      name
      experiment {
        name
      }
    }
  }
`

// FIXME: `currentAssignment { id }` and `availableAssignments { id }` below
// should be redundant in light of the fragments, but when they're removed the
// tests break?
const CompleteAssignmentMutation = graphql`
  mutation CurrentActivityMutation($assignmentID: ID!, $feedback: String!) {
    completeAssignment(assignment: $assignmentID, feedback: $feedback) {
      learner {
        currentAssignment {
          id
        }
        availableAssignments {
          id
        }
        ...NewAchievementDialogFragment
        ...CurrentActivity_learner
        ...SelectActivity_learner
      }
    }
  }
`

const NotateAssignmentMutation = graphql`
  mutation CurrentActivityNotateMutation($assignmentID: ID!, $notes: String!) {
    notateAssignment(assignment: $assignmentID, notes: $notes) {
      id
      notes
    }
  }
`

type CalendarType = 'Google' | 'Microsoft365' | 'Apple'

type AddToCalendarButtonProps = {
  children: ReactNode
  calendarType: CalendarType
  assignment: CurrentActivity_learner$data['currentAssignment']
}

function AddToCalendarButton({
  children,
  calendarType,
  assignment,
}: AddToCalendarButtonProps) {
  const { interact } = useContext(InteractionContext)

  function handleAdd(calendarType: CalendarType) {
    interact('click_addToCalendar', { calendarType })

    const now = new Date()
    atcb_action({
      name: assignment?.activity?.name,
      uid: assignment?.id,
      options: [calendarType],
      location: 'On the job',
      description: 'Flint on-the-job activity.',
      startDate: 'today',
      startTime: now.toISOString().slice(11, 16),
      endTime: new Date(now.getTime() + 1000 * 60 * 15)
        .toISOString()
        .slice(11, 16),
      hideBranding: true,
    })
  }

  return (
    <Button
      variant="secondary"
      onClick={() => handleAdd(calendarType)}
      size="sm"
    >
      {children}
    </Button>
  )
}

type CurrentActivityProps = {
  learner: CurrentActivity_learner$key
  complete: boolean | undefined
}

function CurrentActivity({
  learner: learnerFrag,
  complete,
}: CurrentActivityProps) {
  const learner = useFragment(Fragment, learnerFrag)
  const [completeAssignment] = useMutation(CompleteAssignmentMutation)
  const router = useRouter()
  const navigate = useNavigate({ from: '/lms/activity' })
  const assignment = learner?.currentAssignment
  const activity = assignment?.activity
  const savedNotes = assignment?.notes

  const [notateAssignment] = useMutation(NotateAssignmentMutation)
  const [notes, setNotes] = useState(assignment?.notes || '')

  const competencies = [
    ...new Set(
      activity?.behaviors.nodes.flatMap((b) =>
        b.competency?.id ? [b.competency?.id] : []
      ) || []
    ),
  ].flatMap((id) => {
    const comp = activity?.behaviors.nodes.find(
      (b) => b.competency?.id == id
    )?.competency
    return comp ? [comp] : []
  })

  const updateNotes = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setNotes(e.target.value)
  }

  const saveNotes = useCallback(async () => {
    if (notes !== savedNotes) {
      notateAssignment({ variables: { assignmentID: assignment?.id, notes } })
    }
  }, [assignment?.id, notateAssignment, notes, savedNotes])

  useEffect(() => {
    const interval = setInterval(async () => {
      saveNotes()
    }, 3000)
    return () => clearInterval(interval)
  }, [saveNotes])

  if (!assignment || !activity) return <Waiting />

  const handleSubmit = async (values: Feedback) => {
    saveNotes()
    completeAssignment({
      variables: {
        assignmentID: assignment.id,
        feedback: JSON.stringify(values),
      },
      onCompleted: () => {
        router?.invalidate()
        if (learner.currentInitiative?.totalCount > 0) {
          navigate({
            to: '/lms/initiatives',
            search: {
              activity:
                values.completed === 'Yes' ? 'completed' : 'incompleted',
            },
          })
        } else {
          navigate({ to: '/lms' })
        }
      },
      onError: () => {
        // TODO log this error better
        navigate({ to: '/lms' })
      },
      updater: (store) => {
        // This is a little bit of overkill, but we want to basically reload the whole learner.
        store.get(learner.id)?.invalidateRecord()
      },
    })
  }

  const mandatoryFeedback =
    learner.treatments.find((t) => t.experiment.name === 'MandatoryFeedback')
      ?.name === 'MandatoryFeedback'

  return (
    <InteractionProvider page="activity">
      <Hero>
        <HeroTitle>{activityName(activity)}</HeroTitle>
        <HeroSubtitle>
          The activity you&apos;ve chosen is presented below. When you&apos;ve
          actioned it, mark it complete at the bottom of the page.
        </HeroSubtitle>
      </Hero>
      <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
        <div className="lg:col-span-2">
          {assignment.activity.guidance || assignment.generatedReasoning ? (
            <CardTabs
              defaultValue="instructions"
              className="flex h-full flex-col"
            >
              <CardTabsList>
                <CardTabsTrigger value="instructions">
                  <ListChecks
                    className="w-4 stroke-flintBlue"
                    strokeWidth={2.5}
                  />
                  Instructions
                </CardTabsTrigger>
                {assignment.activity.guidance ? (
                  <CardTabsTrigger value="guidance">
                    <LifeBuoy
                      className="w-4 stroke-flintBlue"
                      strokeWidth={2.5}
                    />
                    More Guidance
                  </CardTabsTrigger>
                ) : null}
                {assignment.generatedReasoning ? (
                  <CardTabsTrigger value="why">
                    <Sparkle
                      className="w-3.5 stroke-flintBlue"
                      strokeWidth={2.5}
                    />
                    AI Personalization
                  </CardTabsTrigger>
                ) : null}
              </CardTabsList>
              <CardTabsContent value="instructions" className="flex-grow">
                <Card tabs className="h-full">
                  <CardContent className="space-y-6">
                    <div className="space-y-2">
                      <h2 className="font-semibold">Objective</h2>
                      <Markdown>{activity.shortDescription}</Markdown>
                    </div>
                    <div className="space-y-2">
                      <h2 className="font-semibold">Instructions</h2>
                      <Markdown>{activity.instructions}</Markdown>
                    </div>
                  </CardContent>
                </Card>
              </CardTabsContent>
              {assignment.activity.guidance ? (
                <CardTabsContent value="guidance" className="flex-grow">
                  <Card tabs className="h-full">
                    <CardContent className="space-y-6">
                      <Markdown>{assignment.activity.guidance}</Markdown>
                    </CardContent>
                  </Card>
                </CardTabsContent>
              ) : null}
              {assignment.generatedReasoning ? (
                <CardTabsContent value="why" className="flex-grow">
                  <Card tabs className="h-full">
                    <CardContent className="space-y-6">
                      <div className="space-y-2">
                        <h2 className="font-semibold">
                          Why Did I Get This Activity?
                        </h2>
                        <Markdown>{assignment.generatedReasoning}</Markdown>
                      </div>
                    </CardContent>
                  </Card>
                </CardTabsContent>
              ) : null}
            </CardTabs>
          ) : (
            <Card className="h-full">
              <CardContent className="space-y-6">
                <div className="space-y-2">
                  <div className="flex items-center gap-2">
                    <Goal className="w-4 stroke-flintBlue" strokeWidth={2.5} />
                    <h2 className="font-semibold">Objective</h2>
                  </div>
                  <Markdown>{activity.shortDescription}</Markdown>
                </div>
                <div className="space-y-2">
                  <div className="flex items-center gap-2">
                    <ListChecks
                      className="w-4 stroke-flintBlue"
                      strokeWidth={2.5}
                    />
                    <h2 className="font-semibold">Instructions</h2>
                  </div>
                  <Markdown>{activity.instructions}</Markdown>
                </div>
                {activity.guidance ? (
                  <div className="space-y-2">
                    <div className="flex items-center gap-2">
                      <LifeBuoy
                        className="w-4 stroke-flintBlue"
                        strokeWidth={2.5}
                      />
                      <h2 className="font-semibold">More Guidance</h2>
                    </div>
                    <Markdown>{activity.guidance}</Markdown>
                  </div>
                ) : null}
              </CardContent>
            </Card>
          )}
        </div>
        <div className="space-y-6">
          {assignment.activity.guidance || assignment.generatedReasoning ? (
            <div className="h-[1.1rem]"></div>
          ) : null}
          <Card>
            <CardContent className="space-y-6">
              {assignment.dueDate ? (
                <div className="space-y-2">
                  <div className="flex items-center gap-2">
                    <CalendarCheck
                      className="w-4 stroke-flintBlue"
                      strokeWidth={2.5}
                    />
                    <h2 className="font-semibold">Due Date</h2>
                  </div>
                  <p className="text-sm">
                    <var>{format(new Date(assignment.dueDate), 'MMMM do')}</var>
                  </p>
                </div>
              ) : null}
              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <CalendarPlus
                    className="w-4 stroke-flintBlue"
                    strokeWidth={2.5}
                  />
                  <h2 className="font-semibold">Add to Calendar</h2>
                </div>
                <div className="flex flex-wrap gap-2">
                  <AddToCalendarButton
                    assignment={assignment}
                    calendarType="Google"
                  >
                    <GoogleCalendar size={16} />
                    Google
                  </AddToCalendarButton>
                  <AddToCalendarButton
                    assignment={assignment}
                    calendarType="Microsoft365"
                  >
                    <Microsoft365 size={16} />
                    Microsoft365
                  </AddToCalendarButton>
                  <AddToCalendarButton
                    assignment={assignment}
                    calendarType="Apple"
                  >
                    <Apple size={13} className="relative -top-px" />
                    Apple
                  </AddToCalendarButton>
                </div>
              </div>
            </CardContent>
          </Card>
          <Card>
            <CardContent className="space-y-6">
              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <BarChartBig
                    className="w-4 stroke-flintBlue"
                    strokeWidth={2.5}
                  />
                  <h2 className="font-semibold">Improve These Behaviors</h2>
                </div>
                <div className="flex flex-wrap gap-2">
                  {activity.behaviors.nodes.map((skill, i) => (
                    <Dialog key={i}>
                      <DialogContent size="md" closeBtn>
                        <DialogHeader>
                          <DialogTitle>{skill.name}</DialogTitle>
                        </DialogHeader>
                        <Markdown>{skill.longDescription}</Markdown>
                        <DialogFooter>
                          <DialogClose asChild>
                            <Button size="sm">Close</Button>
                          </DialogClose>
                        </DialogFooter>
                      </DialogContent>
                      <TooltipProvider delayDuration={150}>
                        <Tooltip>
                          <DialogTrigger asChild>
                            <TooltipTrigger>
                              <div className="cursor-pointer">
                                <Pill>{skill.name}</Pill>
                              </div>
                            </TooltipTrigger>
                          </DialogTrigger>
                          <TooltipContent>
                            {skill.shortDescription}
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </Dialog>
                  ))}
                </div>
              </div>
              {learner.customer.competencyLabel && competencies.length > 0 ? (
                <div className="space-y-2">
                  <div className="flex items-center gap-2">
                    <Home className="w-4 stroke-flintBlue" strokeWidth={2.5} />
                    <h3 className="font-semibold">
                      {learner.customer.competencyLabel}
                    </h3>
                  </div>
                  <div className="flex flex-wrap gap-2">
                    {competencies.map((competency, i) => (
                      <Dialog key={i}>
                        <DialogContent size="md" closeBtn>
                          <DialogHeader>
                            <DialogTitle>{competency.name}</DialogTitle>
                          </DialogHeader>
                          <Markdown>{competency.longDescription}</Markdown>
                          <DialogFooter>
                            <DialogClose asChild>
                              <Button size="sm">Close</Button>
                            </DialogClose>
                          </DialogFooter>
                        </DialogContent>
                        <TooltipProvider key={i} delayDuration={150}>
                          <Tooltip>
                            <DialogTrigger asChild>
                              <TooltipTrigger asChild>
                                <div className="cursor-default">
                                  <Pill>{competency.name}</Pill>
                                </div>
                              </TooltipTrigger>
                            </DialogTrigger>
                            <TooltipContent>
                              {competency.shortDescription}
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </Dialog>
                    ))}
                  </div>
                </div>
              ) : null}
            </CardContent>
          </Card>
        </div>
      </div>
      <div className="space-y-2">
        <div className="inline-flex items-center gap-2">
          <PenSquare className="w-4 stroke-flintBlue" strokeWidth={2.5} />
          <h2 className="font-semibold">
            Record your specific experience doing this activity.{' '}
            {notes && notes === savedNotes ? '(saved)' : null}
          </h2>
        </div>
        <textarea
          value={notes}
          onChange={updateNotes}
          onBlur={saveNotes}
          className="min-h-[8rem] w-full rounded-lg bg-white px-5 py-4 shadow-sm outline-flintOrange ring-1 ring-slate-900/5"
        />
      </div>
      <Survey
        onSubmit={handleSubmit}
        complete={complete}
        mandatoryFeedback={mandatoryFeedback}
      />
    </InteractionProvider>
  )
}

export default CurrentActivity
