import { HelpCircle } from 'lucide-react'
import Markdown from '@/components/Markdown'
import { Button } from '@/components/Button'
import {
  Dialog,
  DialogTrigger,
  DialogTitle,
  DialogHeader,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
} from '@/components/Dialog'
import { graphql, useFragment } from 'react-relay'
import { Template } from './Template'
import { PromptLearnerFragment$key } from './__generated__/PromptLearnerFragment.graphql'
import { PromptAnswerFragment$key } from './__generated__/PromptAnswerFragment.graphql'

const AnswerFragment = graphql`
  fragment PromptAnswerFragment on SurveyAnswer {
    question {
      prompt
      behavior {
        name
        longDescription
      }
    }
  }
`

const LearnerFragment = graphql`
  fragment PromptLearnerFragment on Learner {
    ...TemplateLearnerFragment
  }
`

type PromptProps = {
  answerRef: PromptAnswerFragment$key
  learnerRef: PromptLearnerFragment$key | null
}

export function Prompt({ answerRef, learnerRef }: PromptProps) {
  const answer = useFragment(AnswerFragment, answerRef)
  const learner = useFragment(LearnerFragment, learnerRef)
  const q = answer.question
  return (
    <>
      <Template text={q.prompt} learnerRef={learner} underline={true} />
      {q.behavior?.longDescription ? (
        <Dialog>
          <DialogTrigger asChild>
            <button>
              <HelpCircle
                className="relative -top-0.5 ml-1 inline cursor-pointer stroke-flintBlue opacity-40 duration-200 hover:opacity-100"
                size={18}
                strokeWidth={2}
              />
            </button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>{q.behavior.name}</DialogTitle>
            </DialogHeader>
            <DialogDescription>
              <Markdown>{q.behavior.longDescription}</Markdown>
            </DialogDescription>
            <DialogFooter>
              <DialogClose asChild>
                <Button size="sm">Close</Button>
              </DialogClose>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      ) : null}
    </>
  )
}
