/**
 * @generated SignedSource<<eff2b267ce0a5cf6a18882f2bba0d802>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SelectInitiativeQuery$variables = {};
export type SelectInitiativeQuery$data = {
  readonly learner: {
    readonly initiatives: {
      readonly nodes: ReadonlyArray<{
        readonly choices: {
          readonly nodes: ReadonlyArray<{
            readonly id: string;
            readonly " $fragmentSpreads": FragmentRefs<"SelectInitiativeFragment">;
          }>;
        };
        readonly description: string;
        readonly id: string;
        readonly showChoices: boolean;
      }>;
    };
  } | null;
};
export type SelectInitiativeQuery = {
  response: SelectInitiativeQuery$data;
  variables: SelectInitiativeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "membershipFilter",
    "value": {
      "atDate": 0
    }
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "showChoices",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SelectInitiativeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Learner",
        "kind": "LinkedField",
        "name": "learner",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "LearnerInitiativeConnection",
            "kind": "LinkedField",
            "name": "initiatives",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Initiative",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InitiativeConnection",
                    "kind": "LinkedField",
                    "name": "choices",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Initiative",
                        "kind": "LinkedField",
                        "name": "nodes",
                        "plural": true,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "SelectInitiativeFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "initiatives(membershipFilter:{\"atDate\":0})"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SelectInitiativeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Learner",
        "kind": "LinkedField",
        "name": "learner",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "LearnerInitiativeConnection",
            "kind": "LinkedField",
            "name": "initiatives",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Initiative",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InitiativeConnection",
                    "kind": "LinkedField",
                    "name": "choices",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Initiative",
                        "kind": "LinkedField",
                        "name": "nodes",
                        "plural": true,
                        "selections": [
                          (v1/*: any*/),
                          (v4/*: any*/),
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SkillConnection",
                            "kind": "LinkedField",
                            "name": "behaviors",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Skill",
                                "kind": "LinkedField",
                                "name": "nodes",
                                "plural": true,
                                "selections": [
                                  (v1/*: any*/),
                                  (v4/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "shortDescription",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "longDescription",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "initiatives(membershipFilter:{\"atDate\":0})"
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1fc1822fa560837ec8814532adfe1dce",
    "id": null,
    "metadata": {},
    "name": "SelectInitiativeQuery",
    "operationKind": "query",
    "text": "query SelectInitiativeQuery {\n  learner {\n    initiatives(membershipFilter: {atDate: 0}) {\n      nodes {\n        id\n        description\n        showChoices\n        choices {\n          nodes {\n            id\n            ...SelectInitiativeFragment\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment SelectInitiativeFragment on Initiative {\n  id\n  name\n  description\n  behaviors {\n    nodes {\n      id\n      name\n      shortDescription\n      longDescription\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8fdc7fd1a107d277e5b95b997f90d7ea";

export default node;
