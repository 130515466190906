/**
 * @generated SignedSource<<bc0068f5ac34abcc1e10ebd85ca0969c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type completedAnswersFragment$data = {
  readonly sections: ReadonlyArray<{
    readonly answers: ReadonlyArray<{
      readonly choice: number | null;
      readonly choices: ReadonlyArray<number> | null;
      readonly id: string;
      readonly question: {
        readonly choiceCount: number;
      };
      readonly text: string | null;
    }>;
  }>;
  readonly " $fragmentType": "completedAnswersFragment";
};
export type completedAnswersFragment$key = {
  readonly " $data"?: completedAnswersFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"completedAnswersFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "completedAnswersFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SurveyResponseSection",
      "kind": "LinkedField",
      "name": "sections",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SurveyAnswer",
          "kind": "LinkedField",
          "name": "answers",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "SurveyQuestion",
              "kind": "LinkedField",
              "name": "question",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "choiceCount",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "choice",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "choices",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "text",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SurveyResponse",
  "abstractKey": null
};

(node as any).hash = "9e2d69e4f63e4bb5916ff20a199cb229";

export default node;
