import { graphql, useFragment } from 'react-relay'
import { completedAnswersFragment$key } from './__generated__/completedAnswersFragment.graphql'

const Fragment = graphql`
  fragment completedAnswersFragment on SurveyResponse {
    sections {
      answers {
        id
        question {
          choiceCount
        }
        choice
        choices
        text
      }
    }
  }
`

type useCompletedAnswersProps = {
  responseRef?: completedAnswersFragment$key | null
}

export function useCompletedAnswers({ responseRef }: useCompletedAnswersProps) {
  const response = useFragment(Fragment, responseRef)
  return (
    response?.sections
      .flatMap((s) => s.answers)
      .filter(
        (answer) =>
          (answer.question.choiceCount > 0 &&
            (answer.choice != null || answer.choices?.length)) ||
          answer.text?.length
      )
      .map((a) => a.id) ?? []
  )
}
