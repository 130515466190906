import { graphql, useFragment } from 'react-relay'
import { goalProgressHookLearnerFragment$key } from './__generated__/goalProgressHookLearnerFragment.graphql'
import { goalProgressHookEdgeFragment$key } from './__generated__/goalProgressHookEdgeFragment.graphql'

const LearnerFragment = graphql`
  fragment goalProgressHookLearnerFragment on Learner {
    currentPathway {
      pathway {
        steps {
          id
        }
      }
      stepsCompleted
    }
  }
`

const EdgeFragment = graphql`
  fragment goalProgressHookEdgeFragment on LearnerInitiativeEdge {
    initiative: node {
      goalActivityCount
    }
    completedAssignments: assignments(filter: { feedbackCompleted: true }) {
      totalCount
    }
  }
`

export function useGoalProgress(
  learnerRef: goalProgressHookLearnerFragment$key,
  edgeRef: goalProgressHookEdgeFragment$key
) {
  const learner = useFragment(LearnerFragment, learnerRef)
  const edge = useFragment(EdgeFragment, edgeRef)

  const totalSteps =
    edge?.initiative?.goalActivityCount ||
    learner?.currentPathway?.pathway.steps.length ||
    0

  const stepsCompleted =
    edge?.initiative?.goalActivityCount !== null
      ? edge.completedAssignments.totalCount
      : learner?.currentPathway?.stepsCompleted || 0

  const hasPathway = !!learner?.currentPathway

  return {
    totalSteps,
    stepsCompleted,
    hasPathway,
  }
}
