/**
 * @generated SignedSource<<0aa6fb5e9cfb8ebba050ece4beb0890b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type LMSRedirectQuery$variables = {};
export type LMSRedirectQuery$data = {
  readonly learner: {
    readonly availableAssignments: ReadonlyArray<{
      readonly id: string;
    }>;
    readonly currentAssignment: {
      readonly id: string;
    } | null;
    readonly customer: {
      readonly id: string;
      readonly name: string;
    };
    readonly directReports: {
      readonly nodes: ReadonlyArray<{
        readonly id: string;
        readonly pendingSurveyFromManager: {
          readonly id: string;
        } | null;
        readonly surveyAwaitingResponseFromManager: {
          readonly id: string;
        } | null;
        readonly treatments: ReadonlyArray<{
          readonly id: string;
          readonly treatmentType: string;
        }>;
      }>;
    };
    readonly initiatives: {
      readonly edges: ReadonlyArray<{
        readonly initiative: {
          readonly choices: {
            readonly totalCount: number;
          };
          readonly id: string;
          readonly showChoices: boolean;
        };
        readonly seenIntro: boolean;
      }>;
    };
    readonly needsToProvideEmail: boolean;
    readonly pastInitiatives: {
      readonly edges: ReadonlyArray<{
        readonly endDate: number;
        readonly initiative: {
          readonly completionDoneContent: string;
          readonly id: string;
        };
        readonly seenEnd: boolean;
      }>;
    };
    readonly pendingSurvey: {
      readonly id: string;
    } | null;
    readonly receivingActivities: boolean;
    readonly seenWelcome: boolean;
    readonly surveyAwaitingResponse: {
      readonly id: string;
    } | null;
  } | null;
};
export type LMSRedirectQuery = {
  response: LMSRedirectQuery$data;
  variables: LMSRedirectQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "seenWelcome",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "receivingActivities",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "LearnerConnection",
  "kind": "LinkedField",
  "name": "directReports",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Learner",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "SurveyResponse",
          "kind": "LinkedField",
          "name": "pendingSurveyFromManager",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SurveyAssignment",
          "kind": "LinkedField",
          "name": "surveyAwaitingResponseFromManager",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Treatment",
          "kind": "LinkedField",
          "name": "treatments",
          "plural": true,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "treatmentType",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Assignment",
  "kind": "LinkedField",
  "name": "currentAssignment",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "SurveyResponse",
  "kind": "LinkedField",
  "name": "pendingSurvey",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "SurveyAssignment",
  "kind": "LinkedField",
  "name": "surveyAwaitingResponse",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Assignment",
  "kind": "LinkedField",
  "name": "availableAssignments",
  "plural": true,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "membershipFilter",
      "value": {
        "atDate": 0
      }
    }
  ],
  "concreteType": "LearnerInitiativeConnection",
  "kind": "LinkedField",
  "name": "initiatives",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnerInitiativeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": "seenIntro",
          "args": [
            {
              "kind": "Literal",
              "name": "popupType",
              "value": "InitiativePopupIntro"
            }
          ],
          "kind": "ScalarField",
          "name": "seenPopup",
          "storageKey": "seenPopup(popupType:\"InitiativePopupIntro\")"
        },
        {
          "alias": "initiative",
          "args": null,
          "concreteType": "Initiative",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "showChoices",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "InitiativeConnection",
              "kind": "LinkedField",
              "name": "choices",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "totalCount",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "initiatives(membershipFilter:{\"atDate\":0})"
},
v10 = {
  "alias": "pastInitiatives",
  "args": [
    {
      "kind": "Literal",
      "name": "membershipFilter",
      "value": {
        "beforeDate": 0
      }
    }
  ],
  "concreteType": "LearnerInitiativeConnection",
  "kind": "LinkedField",
  "name": "initiatives",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnerInitiativeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": "seenEnd",
          "args": [
            {
              "kind": "Literal",
              "name": "popupType",
              "value": "InitiativePopupEnd"
            }
          ],
          "kind": "ScalarField",
          "name": "seenPopup",
          "storageKey": "seenPopup(popupType:\"InitiativePopupEnd\")"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endDate",
          "storageKey": null
        },
        {
          "alias": "initiative",
          "args": null,
          "concreteType": "Initiative",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "completionDoneContent",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "initiatives(membershipFilter:{\"beforeDate\":0})"
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "needsToProvideEmail",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Customer",
  "kind": "LinkedField",
  "name": "customer",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LMSRedirectQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Learner",
        "kind": "LinkedField",
        "name": "learner",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LMSRedirectQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Learner",
        "kind": "LinkedField",
        "name": "learner",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "151674b558662bd482cc7128aaee62c9",
    "id": null,
    "metadata": {},
    "name": "LMSRedirectQuery",
    "operationKind": "query",
    "text": "query LMSRedirectQuery {\n  learner {\n    seenWelcome\n    receivingActivities\n    directReports {\n      nodes {\n        id\n        pendingSurveyFromManager {\n          id\n        }\n        surveyAwaitingResponseFromManager {\n          id\n        }\n        treatments {\n          id\n          treatmentType\n        }\n      }\n    }\n    currentAssignment {\n      id\n    }\n    pendingSurvey {\n      id\n    }\n    surveyAwaitingResponse {\n      id\n    }\n    availableAssignments {\n      id\n    }\n    initiatives(membershipFilter: {atDate: 0}) {\n      edges {\n        seenIntro: seenPopup(popupType: InitiativePopupIntro)\n        initiative: node {\n          id\n          showChoices\n          choices {\n            totalCount\n          }\n        }\n      }\n    }\n    pastInitiatives: initiatives(membershipFilter: {beforeDate: 0}) {\n      edges {\n        seenEnd: seenPopup(popupType: InitiativePopupEnd)\n        endDate\n        initiative: node {\n          id\n          completionDoneContent\n        }\n      }\n    }\n    needsToProvideEmail\n    customer {\n      id\n      name\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "f275f953a1cf3579b93b5f5686b18e31";

export default node;
