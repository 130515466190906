import { truncateString } from '@/common/utils'
import { Hero, HeroTitle } from '@/components/lms/Hero'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/Select'
import { Switcher, SwitcherItem } from '@/components/Switcher'
import { useState } from 'react'
import { graphql, useFragment } from 'react-relay'
import { useSearch } from '@tanstack/react-router'
import Initiative from './Initiative'
import { FirstTimeDialog } from './FirstTimeDialog'
import {
  InitiativeSelectorLearnerFragment$key,
  InitiativeSelectorLearnerFragment$data,
} from './__generated__/InitiativeSelectorLearnerFragment.graphql'
import ActivityHistoryCard from './ActivityHistoryCard'
import { EndDialog } from './EndDialog'
import { CompletedDialog } from './CompletedDialog'

const Fragment = graphql`
  fragment InitiativeSelectorLearnerFragment on Learner {
    ...InitiativeLearnerFragment
    ...EndDialogLearnerFragment
    ...ActivityHistoryCardFragment
    ...CompletedDialogLearnerFragment
    ...FirstTimeDialogLearnerFragment
    currentInitiative: initiatives(membershipFilter: { atDate: 0 }) {
      edges {
        ...InitiativeEdgeFragment
        ...FirstTimeDialogEdgeFragment
        ...CompletedDialogEdgeFragment
        initiative: node {
          id
          showChoices
        }
      }
    }
    pastInitiatives: initiatives(membershipFilter: { beforeDate: 0 }) {
      edges {
        initiative: node {
          id
          name
          showChoices
        }
        endDate

        seenEnd: seenPopup(popupType: InitiativePopupEnd)
        assignments(filter: { chosen: true }) {
          totalCount
        }
        ...InitiativeEdgeFragment
        ...EndDialogEdgeFragment
      }
    }
  }
`

type PastInitiativesProps = {
  learner: InitiativeSelectorLearnerFragment$data
  showInteractivity: boolean
  learnerIsCurrent: boolean
  pastInitiatives: InitiativeSelectorLearnerFragment$data['pastInitiatives']['edges'][number][]
  selectedPastInitiative: InitiativeSelectorLearnerFragment$data['pastInitiatives']['edges'][number]
  handlePastInitiativeChange: (value: string) => void
}

function PastInitiatives({
  learner,
  showInteractivity,
  learnerIsCurrent,
  pastInitiatives,
  selectedPastInitiative,
  handlePastInitiativeChange,
}: PastInitiativesProps) {
  const isMostRecent =
    selectedPastInitiative.initiative.id === pastInitiatives[0].initiative.id

  return (
    <>
      <div className="w-72">
        <Select onValueChange={handlePastInitiativeChange}>
          <SelectTrigger>
            <SelectValue
              placeholder={truncateString(
                selectedPastInitiative.initiative.name,
                28
              )}
            />
          </SelectTrigger>
          <SelectContent>
            {pastInitiatives.map((initiative) => (
              <SelectItem
                key={initiative.initiative.id}
                value={initiative.initiative.name}
              >
                {truncateString(initiative.initiative.name, 28)}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      {isMostRecent && showInteractivity ? (
        <EndDialog edgeRef={selectedPastInitiative} learnerRef={learner} />
      ) : null}
      <Initiative
        edgeRef={selectedPastInitiative}
        learnerRef={learner}
        learnerIsCurrent={learnerIsCurrent}
      />
    </>
  )
}

type CurrentInitiativeProps = {
  currentInitiative: InitiativeSelectorLearnerFragment$data['currentInitiative']['edges'][number]
  learner: InitiativeSelectorLearnerFragment$data
  learnerIsCurrent: boolean
  showInteractivity: boolean
}
function CurrentInitiative({
  currentInitiative,
  learner,
  learnerIsCurrent,
  showInteractivity,
}: CurrentInitiativeProps) {
  const searchParams = useSearch({ strict: false })
  const activityParam = searchParams.activity

  const activityCompleted = activityParam === 'completed'
  const activityIncompleted = activityParam === 'incompleted'

  return (
    <>
      {showInteractivity ? (
        <>
          <FirstTimeDialog edgeRef={currentInitiative} learnerRef={learner} />
          <CompletedDialog edgeRef={currentInitiative} learnerRef={learner} />
        </>
      ) : null}
      <Initiative
        edgeRef={currentInitiative}
        learnerRef={learner}
        activityCompleted={activityCompleted}
        activityIncompleted={activityIncompleted}
        showInteractivity={showInteractivity}
        learnerIsCurrent={learnerIsCurrent}
      />
    </>
  )
}

type InitiativeSelectorProps = {
  learnerRef: InitiativeSelectorLearnerFragment$key
  showHero?: boolean
  showInteractivity?: boolean
  learnerIsCurrent?: boolean
}

export default function InitiativeSelector({
  learnerRef,
  showHero,
  showInteractivity = false,
  learnerIsCurrent = true,
}: InitiativeSelectorProps) {
  const learner = useFragment(Fragment, learnerRef)

  const currentInitiative = learner.currentInitiative?.edges.filter(
    (initiative) => !initiative.initiative.showChoices
  )[0]
  const pastInitiatives = (
    learner.pastInitiatives?.edges.filter(
      (initiative) => !initiative.initiative.showChoices
    ) || []
  ).sort((a, b) => b.endDate - a.endDate)

  type InitiativeState =
    | {
        type: 'current'
        currentInitiative: NonNullable<typeof currentInitiative>
      }
    | {
        type: 'past'
        pastInitiatives: NonNullable<typeof pastInitiatives>
        selectedPastInitiative: (typeof pastInitiatives)[number]
      }
    | { type: 'all' }

  const [activeSwitcher, setActiveSwitcher] = useState<InitiativeState>(
    (): InitiativeState => {
      if (pastInitiatives.length > 0 && !pastInitiatives[0].seenEnd)
        return {
          type: 'past',
          pastInitiatives,
          selectedPastInitiative: pastInitiatives[0],
        }
      if (currentInitiative) return { type: 'current', currentInitiative }
      if (pastInitiatives.length > 0)
        return {
          type: 'past',
          pastInitiatives,
          selectedPastInitiative: pastInitiatives[0],
        }
      return { type: 'all' }
    }
  )

  const handlePastInitiativeChange = (value: string) => {
    const selectedInitiative = pastInitiatives.find(
      (initiative) => initiative.initiative.name === value
    )
    if (selectedInitiative) {
      setActiveSwitcher((prev) => ({
        ...prev,
        selectedPastInitiative: selectedInitiative,
      }))
    }
  }

  return (
    <>
      <div className="flex flex-wrap items-end justify-between gap-6">
        <Hero>
          {showHero ? (
            <HeroTitle>
              {activeSwitcher.type === 'all'
                ? 'All Past Activities'
                : activeSwitcher.type === 'past'
                  ? 'Past Initiatives'
                  : 'Current Initiative'}
            </HeroTitle>
          ) : null}
        </Hero>
        {pastInitiatives.length > 0 || currentInitiative ? (
          <Switcher<typeof activeSwitcher.type>
            activeSwitcher={activeSwitcher.type}
            onSwitcherChange={(value) => {
              if (value === 'past') {
                setActiveSwitcher({
                  type: 'past',
                  pastInitiatives,
                  selectedPastInitiative: pastInitiatives[0],
                })
              } else if (value === 'current') {
                setActiveSwitcher({
                  type: 'current',
                  currentInitiative,
                })
              } else {
                setActiveSwitcher({ type: 'all' })
              }
            }}
          >
            {currentInitiative ? (
              <SwitcherItem value="current">Current Initiative</SwitcherItem>
            ) : null}
            {pastInitiatives.length > 0 ? (
              <SwitcherItem value="past">Past Initiatives</SwitcherItem>
            ) : null}
            <SwitcherItem value="all">All Past Activities</SwitcherItem>
          </Switcher>
        ) : null}
      </div>
      {activeSwitcher.type === 'past' ? (
        <PastInitiatives
          learner={learner}
          learnerIsCurrent={learnerIsCurrent}
          pastInitiatives={pastInitiatives}
          selectedPastInitiative={activeSwitcher.selectedPastInitiative}
          handlePastInitiativeChange={handlePastInitiativeChange}
          showInteractivity={showInteractivity}
        />
      ) : null}

      {activeSwitcher.type === 'current' ? (
        <CurrentInitiative
          currentInitiative={activeSwitcher.currentInitiative}
          learner={learner}
          learnerIsCurrent={learnerIsCurrent}
          showInteractivity={showInteractivity}
        />
      ) : null}
      {activeSwitcher.type === 'all' ? (
        <ActivityHistoryCard
          learnerRef={learner}
          showDescription={learnerIsCurrent}
        />
      ) : null}
    </>
  )
}
